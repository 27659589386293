var permission = {
  state: {
    routers: [],
    purview: [],
    //权限指令
    isAdmin: null // 是否管理员和子账号管理员
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.routers = routers;
    },
    SET_PER: function SET_PER(state, per) {
      state.purview = per;
    },
    SET_ADMIN: function SET_ADMIN(state, val) {
      state.isAdmin = val;
    },
    CLEAR_ROUTERS: function CLEAR_ROUTERS(state) {
      state.routers = [];
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    },
    setPermission: function setPermission(_ref2, per) {
      var commit = _ref2.commit;
      commit('SET_PER', per);
    },
    setIsAdmin: function setIsAdmin(_ref3, val) {
      var commit = _ref3.commit;
      commit('SET_ADMIN', val);
    },
    clearRouters: function clearRouters(_ref4) {
      var commit = _ref4.commit;
      commit('CLEAR_ROUTERS');
    }
  }
};
export default permission;