var state = {
  list: [{
    name: '首页',
    path: '/dashboard'
  }]
};
var mutations = {
  ADD_TAB: function ADD_TAB(state, item) {
    state.list.push(item);
  },
  DEL_TAB: function DEL_TAB(state, path) {
    state.list = state.list.filter(function (item) {
      return item.path != path;
    });
  },
  INIT_TAB: function INIT_TAB(state) {
    state.list = [{
      name: '首页',
      path: '/dashboard'
    }];
  },
  CLEAR_TAB: function CLEAR_TAB(state) {
    state.list = [{
      name: '首页',
      path: '/dashboard'
    }];
  }
};
var actions = {
  initTab: function initTab(_ref) {
    var commit = _ref.commit;
    commit('INIT_TAB');
  },
  addTab: function addTab(_ref2, item) {
    var commit = _ref2.commit;
    commit('ADD_TAB', item);
  },
  delTab: function delTab(_ref3, item) {
    var commit = _ref3.commit;
    commit('DEL_TAB', item);
  },
  clearTab: function clearTab(_ref4) {
    var commit = _ref4.commit;
    commit('CLEAR_TAB');
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};