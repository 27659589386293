import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "./requireIcons";
export default {
  name: 'IconSelect',
  data: function data() {
    return {
      name: '',
      iconList: icons
    };
  },
  methods: {
    filterIcons: function filterIcons() {
      var _this = this;
      this.iconList = icons;
      if (this.name) {
        this.iconList = this.iconList.filter(function (item) {
          return item.includes(_this.name);
        });
      }
    },
    selectedIcon: function selectedIcon(name) {
      this.$emit('selected', name);
      document.body.click();
    },
    reset: function reset() {
      this.name = '';
      this.iconList = icons;
    }
  }
};