//
//
//
//
//
//
//

import Logo from '@/assets/login/logo.png';
export default {
  name: 'SidebarLogo',
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function data() {
    return {
      title: '代理管理网',
      logo: Logo
    };
  }
};