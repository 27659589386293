import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.to-string";
var _this = this;
/**
   * 获取最近几天天日期
   * @param {*} day 
   */
export var getDay = function getDay(day) {
  var that = _this;
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};
export var doHandleMonth = function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
};

// 获取本周的时间

export var getCurrentWeek = function getCurrentWeek() {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var oneDayTime = 24 * 60 * 60 * 1000;
  var MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
  var SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日

  return [new Date(timeConvert(MondayTime) + ' 12:00:00'), new Date(timeConvert(SundayTime) + ' 11:59:59')];
};

// 获取本月时间

export var getCurrentMonth = function getCurrentMonth() {
  var now = new Date(); // 获取当前日期时间
  var monthStart = new Date(now.getFullYear(), now.getMonth(), 1); // 获取本月第一天的日期时间
  var monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // 获取本月最后一天的日期时间（时间为23:59:59）
  return [monthStart, monthEnd];
};

// 获取上周的日期

export var getLastWeek = function getLastWeek() {
  var now = new Date(); // 获取当前日期时间
  var nowYear = now.getYear(); //当前年
  nowYear += nowYear < 2000 ? 1900 : 0; //
  var now = new Date(); //当前日期
  var nowDayOfWeek = now.getDay() - 1; //今天本周的第几天
  var nowDay = now.getDate(); //当前日
  var nowMonth = now.getMonth(); //当前月

  nowYear += nowYear < 2000 ? 1900 : 0;

  //获得上周的开始日期
  var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 7, 12, 0, 0);
  var weekEndDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 1, 11, 59, 59);
  return [weekStartDate, weekEndDate];
};

//获得上月开始时间
export var getLastMonth = function getLastMonth() {
  var now = new Date(); //当前日期
  var nowYear = now.getYear(); //当前年
  nowYear += nowYear < 2000 ? 1900 : 0; //
  var lastMonthDate = new Date(); //上月日期
  lastMonthDate.setDate(1);
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  var lastMonth = lastMonthDate.getMonth();
  if (lastMonth == 11) {
    var lastMonthStartDate = new Date(nowYear - 1, lastMonth, 1, 12, 0, 0);
  } else {
    var lastMonthStartDate = new Date(nowYear, lastMonth, 1, 12, 0, 0);
  }
  if (lastMonth == 11) {
    var lastMonthEndDate = new Date(nowYear - 1, lastMonth, getMonthDays(lastMonth), 11, 59, 59);
  } else {
    var lastMonthEndDate = new Date(nowYear, lastMonth, getMonthDays(lastMonth), 11, 59, 59);
  }
  return [lastMonthStartDate, lastMonthEndDate];
};
export var formatDate = function formatDate(date) {
  var myyear = date.getFullYear();
  var mymonth = date.getMonth() + 1;
  var myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = "0" + mymonth;
  }
  if (myweekday < 10) {
    myweekday = "0" + myweekday;
  }
  return myyear + "-" + mymonth + "-" + myweekday;
};
export var getMonthDays = function getMonthDays(myMonth) {
  var now = new Date(); //当前日期
  var nowYear = now.getYear(); //当前年
  nowYear += nowYear < 2000 ? 1900 : 0; //
  var lastMonthDate = new Date(); //上月日期
  lastMonthDate.setDate(1);
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
};
export var timeConvert = function timeConvert(time) {
  var date = new Date(time);
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
};
export var getNowTime = function getNowTime() {
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear(); //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();
  var time = year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second);
  return time;
};
//小于10的拼接上0字符串
function addZero(s) {
  return s < 10 ? '0' + s : s;
}
;
export var formatDateTime = function formatDateTime(time) {
  var date = new date(time);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? '0' + second : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};