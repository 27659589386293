import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import elementTwLocale from 'element-ui/lib/locale/lang/zh-TW'; // element-ui lang
import elementThLocale from 'element-ui/lib/locale/lang/th'; // element-ui lang
import elementViLocale from 'element-ui/lib/locale/lang/vi'; // element-ui lang
import elementKoLocale from 'element-ui/lib/locale/lang/ko'; // element-ui lang
import enLocale from "./en";
import zhLocale from "./zh";
import twLocale from "./tw";
import thLocale from "./th";
import viLocale from "./vi";
import koLocale from "./ko";
elementKoLocale.el.pagination.pagesize = '/페이지'; // 重写翻译

Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, elementEnLocale), enLocale),
  zh: _objectSpread(_objectSpread({}, elementZhLocale), zhLocale),
  tw: _objectSpread(_objectSpread({}, elementTwLocale), twLocale),
  th: _objectSpread(_objectSpread({}, elementThLocale), thLocale),
  vi: _objectSpread(_objectSpread({}, elementViLocale), viLocale),
  ko: _objectSpread(_objectSpread({}, elementKoLocale), koLocale)
};
var langType = localStorage.getItem('lang') || 'zh';
localStorage.setItem('lang', langType);
var i18n = new VueI18n({
  locale: langType,
  // set locale
  messages: messages // set locale messages
});
export default i18n;