var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "top-right" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("欢迎使用代理后台")) + " !")]),
      ]),
      _c("img", { attrs: { src: require("../assets/images/welcome.png") } }),
    ]),
    _c(
      "div",
      { staticClass: "notice-list" },
      _vm._l(_vm.list, function (item, i) {
        return _c("div", { key: i, staticClass: "item" }, [
          _c("p", { staticClass: "content" }, [_vm._v(_vm._s(item.cnContent))]),
          _c("p", { staticClass: "time" }, [_vm._v(_vm._s(item.time))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }