//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { serverMsg } from '@/utils/serverMsg';
import { validatePwd } from '@/utils/validate';
import { setPwd as _setPwd } from '@/utils/randomPwd.js';
import store from '@/store';
import { updatePassWord } from '@/api/login';
export default {
  data: function data() {
    var _this = this;
    var confirmPass = function confirmPass(rule, value, callback) {
      if (value) {
        if (_this.passWordForm.newPassword !== value) {
          callback(new Error(_this.$i18n.t('updatePwd.tips1')));
        } else {
          callback();
        }
      } else {
        callback(new Error(_this.$i18n.t('updatePwd.mmbit')));
      }
    };
    var validatePassWord = function validatePassWord(rule, value, callback) {
      // 判断传入的值是否可以通过校验
      if (!validatePwd(value)) {
        callback(new Error(_this.$i18n.t('updatePwd.mmbit')));
      } else {
        callback();
      }
    };
    return {
      passWordForm: {
        oldPassword: '',
        // 旧密码
        newPassword: '',
        // 新密码
        random: '',
        // 随机密码
        confirmPass: ''
      },
      rules: {
        oldPassword: [{
          required: true,
          validator: validatePassWord,
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          validator: validatePassWord,
          trigger: 'blur'
        }],
        confirmPass: [{
          required: true,
          validator: confirmPass,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 重置表单
    resetForm: function resetForm() {
      this.$refs['form'].resetFields();
      this.passWordForm = {
        oldPassword: '',
        // 旧密码
        newPassword: '',
        // 新密码
        random: '',
        // 随机密码
        confirmPass: ''
      };
    },
    doSubmit: function doSubmit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          updatePassWord(_this2.passWordForm).then(function (res) {
            if (res.code == 200) {
              _this2.$alert(serverMsg(res.code), _this2.$i18n.t('updatePwd.wxts')).then(function () {
                store.dispatch('LogOut').then(function () {
                  location.reload();
                });
              });
            } else {
              _this2.$alert(serverMsg(res.code), _this2.$i18n.t('updatePwd.wxts'), {
                confirmButtonText: _this2.$i18n.t('updatePwd.queren'),
                type: 'warning'
              });
            }
          }).catch(function (err) {});
        }
      });
    },
    // 获取随机密码
    setPwd: function setPwd() {
      this.passWordForm.random = _setPwd();
    }
  }
};