var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-logo-container" }, [
    _c("img", { attrs: { src: require("@assets/images/logoIcon.png") } }),
    !_vm.isCollapse ? _c("p", [_vm._v(_vm._s(_vm.$t("代理后台")))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }