var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("sidebar", {
        staticClass: "sidebar-container",
        style: { width: _vm.isCollapse ? "60px" : "230px" },
        attrs: { isCollapse: _vm.isCollapse },
      }),
      _c(
        "div",
        {
          staticClass: "main-container hasTagsView",
          style: {
            marginLeft: 0,
            width: _vm.isCollapse ? "calc(100% - 60px)" : "calc(100% - 230px)",
          },
        },
        [
          _c(
            "div",
            { staticClass: "fixed-header" },
            [
              _c("navbar", {
                attrs: { isCollapse: _vm.isCollapse },
                on: { changeCol: _vm.changeCol },
              }),
              _c("tags-view"),
            ],
            1
          ),
          _c("app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }