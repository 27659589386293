import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.string.pad-start";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { updatePassWord, getBalance } from '@/api/login';
import { Loading } from 'element-ui';
import { encryptWithRsa } from '@/utils/rsaEncrypt';
var _this;
export default {
  name: 'Navbar',
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {},
  inject: ['reload'],
  data: function data() {
    var _this2 = this;
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
    return {
      balance: '泰达币USDT',
      balanceOpt: [],
      options: [{
        value: 'zh',
        label: '中文简体'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'th',
        label: 'ภาษาไทย'
      }, {
        value: 'vi',
        label: 'Tiếng Việt'
      }, {
        value: 'ko',
        label: '한국어'
      }],
      dialogVisible: true,
      lists: [],
      dialogFormVisible: false,
      passWord: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: ''
      },
      isFull: false,
      rules: {
        oldPassword: [{
          required: true,
          message: _this.$t('passWord.tips1'),
          trigger: 'blur'
        }, {
          min: 6,
          max: 12,
          message: _this.$t('passWord.errTips'),
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error(_this.$t('passWord.tips2')));
            } else if (!reg.test(value)) {
              callback(new Error(_this.$t('passWord.errTips')));
            } else {
              if (_this2.passWord.newPassword !== '') {
                _this2.$refs.passWordForm.validateField('repeatPassword');
              }
              callback();
            }
          },
          trigger: 'blur'
        }],
        repeatPassword: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error(_this.$t('passWord.tips3')));
            } else if (!reg.test(value)) {
              callback(new Error(_this.$t('passWord.errTips')));
            } else if (value !== _this2.passWord.newPassword) {
              callback(new Error(_this.$t('passWord.tips4')));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['device', 'user', 'baseApi', 'userInfo', 'langType'])),
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.getBalanceQuery();
  },
  methods: {
    format_with_mod: function format_with_mod(number) {
      var n = number;
      var r = '';
      var temp = '';
      var mod;
      do {
        mod = n % 1000;
        n = n / 1000;
        temp = ~~mod;
        r = (n >= 1 ? "".concat(temp).padStart(3, '0') : temp) + (!!r ? ',' + r : '');
      } while (n >= 1);
      var strNumber = number + '';
      var index = strNumber.indexOf('.');
      if (index >= 0) {
        r += strNumber.substring(index);
      }
      return r;
    },
    getBalanceQuery: function getBalanceQuery() {
      var _this3 = this;
      getBalance().then(function (res) {
        if (res.code == 200) {
          if (Object.keys(res.data).length > 0) {
            var arr = [];
            Object.keys(res.data).forEach(function (key) {
              arr.push({
                label: (key.includes('USDT') ? '$' : '¥') + ' ' + _this3.format_with_mod(res.data[key]),
                value: key
              });
            });
            _this3.balanceOpt = arr;
            if (res.data['泰达币USDT'] <= 0) {
              _this3.$message.error(_this3.$t('余额不足，联系游戏运营商'));
            }
          } else {
            _this3.balanceOpt = [];
          }
        } else {
          _this3.$message.error(_this3.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {});
    },
    onFullScreen: function onFullScreen() {
      if (document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled) {
        console.log('浏览器支持全屏功能');
      } else {
        console.log('浏览器不支持全屏功能');
      }
      if (this.isFull) {
        this.exitFullscreen();
      } else {
        this.fullScreen();
      }
    },
    fullScreen: function fullScreen() {
      var element = document.getElementById('app');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // Internet Explorer and Edge
        element.msRequestFullscreen();
      }
      this.isFull = true;
    },
    exitFullscreen: function exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // Internet Explorer and Edge
        document.msExitFullscreen();
      }
      this.isFull = false;
    },
    onRefreshPage: function onRefreshPage() {
      this.reload();
    },
    collapseSiderbar: function collapseSiderbar() {
      this.$emit('changeCol', !this.isCollapse);
    },
    handleCommand: function handleCommand(command) {
      if (command === 'logout') {
        this.open();
      } else if (command === 'editPassword') {
        this.dialogFormVisible = true;
      } else {}
    },
    submitEdit: function submitEdit() {
      var _this4 = this;
      this.$refs['passWordForm'].validate(function (valid) {
        if (valid) {
          var loading = Loading.service({
            fullscreen: true
          });
          var params = {
            oldPassword: encryptWithRsa(_this4.passWord.oldPassword),
            newPassword: encryptWithRsa(_this4.passWord.newPassword)
          };
          updatePassWord(params).then(function (res) {
            if (res.code == 200) {
              _this4.$message.success(_this4.$t('操作成功'));
              _this4.logout();
            } else {
              _this4.$message.error(_this4.$t(res.message));
            }
          }).catch(function (err) {}).finally(function () {
            _this4.$nextTick(function () {
              loading.close();
            });
          });
        }
      });
    },
    concelEdit: function concelEdit() {
      this.$refs['passWordForm'].resetFields();
      this.dialogFormVisible = false;
    },
    open: function open() {
      var _this5 = this;
      this.$confirm(this.$i18n.t('home.tips'), this.$i18n.t('home.wxts'), {
        confirmButtonText: this.$i18n.t('home.btn_sure'),
        cancelButtonText: this.$i18n.t('home.btn_cancel'),
        type: 'warning'
      }).then(function () {
        _this5.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch('initTab');
      this.$store.dispatch('clearUserInfo');
      this.$store.dispatch('clearRouters');
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    },
    // 语言选择
    langChange: function langChange(type) {
      this.$i18n.locale = type;
      localStorage.setItem('lang', type);
      this.$store.dispatch('initTab');
      this.$store.dispatch('clearRouters');
      this.$store.dispatch('setLang', type);
      if (this.$route.path != '/dashboard') {
        this.$router.push('/dashboard');
      }
      setTimeout(function () {
        location.reload();
      }, 100);
    }
  }
};