import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { serverMsg } from '@/utils/serverMsg';
import { getDay } from '@/utils/date';
import { getNoticeList, saveNotice, publishNotice, updateNotice, disabledNotice, deleteNotice } from '@/api/notice';
export default {
  name: 'settingNotice',
  data: function data() {
    return {
      editType: 0,
      time: '',
      loading: true,
      stateList: [{
        "value": "",
        "label": this.$i18n.t('settingNotice.optionsAll')
      }, {
        "value": "NORMAL",
        "label": this.$i18n.t('settingNotice.optionsNormal')
      }, {
        "value": "CLOSE",
        "label": this.$i18n.t('settingNotice.optionsStop')
      }, {
        "value": "UNPUBLISHED",
        "label": this.$i18n.t('settingNotice.optionsUnpublish')
      }],
      options: [{
        label: this.$i18n.t('settingNotice.company'),
        value: 'COMPANY'
      }, {
        label: this.$i18n.t('settingNotice.partner'),
        value: 'PARTNER'
      }, {
        label: this.$i18n.t('settingNotice.gengeral'),
        value: "GENGERAL"
      }, {
        label: this.$i18n.t('settingNotice.agent'),
        value: "AGENT"
      }, {
        label: this.$i18n.t('settingNotice.subagent'),
        value: "SUBAGENT"
      }],
      queryForm: {
        "parentid": '',
        "pageNo": 1,
        // 页码
        "pageSize": 20,
        // 页数
        "startdate": "",
        // 开始时间
        "enddate": "",
        // 结束时间
        "state": "",
        // 状态
        "agents": ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"] // 层级显示
      },
      noticeForm: _defineProperty(_defineProperty(_defineProperty({
        cnContent: '',
        // 中文内容
        hkContent: ''
      }, "cnContent", ''), "enContent", ''), "agents", ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"]),
      dialog: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      rules: {
        cnContent: [{
          required: true,
          message: this.$i18n.t('settingNotice.form_input'),
          trigger: 'blur'
        }],
        hkContent: [{
          required: true,
          message: this.$i18n.t('settingNotice.form_input'),
          trigger: 'blur'
        }],
        enContent: [{
          required: true,
          message: this.$i18n.t('settingNotice.form_input'),
          trigger: 'blur'
        }]
      },
      editItem: {}
    };
  },
  created: function created() {
    this.queryForm.parentid = this.$store.state.user.userInfo.id;
    this.time = [new Date(getDay(-90) + ' 12:00:00'), new Date(getDay(1) + ' 11:59:59')];
    this.queryForm.startdate = getDay(-90) + ' 12:00:00';
    this.queryForm.enddate = getDay(1) + ' 11:59:59';
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 日期选择
    timeChange: function timeChange(time) {
      if (time.length > 0) {
        this.queryForm.startTime = time[0];
        this.queryForm.endTime = time[1];
      }
    },
    publishHandle: function publishHandle(item) {
      var _this = this;
      this.$confirm(this.$i18n.t('settingNotice.alert_pub'), this.$i18n.t('settingNotice.alert_title'), {
        confirmButtonText: this.$i18n.t('settingNotice.form_quren'),
        cancelButtonText: this.$i18n.t('settingNotice.form_cancel'),
        type: 'warning'
      }).then(function () {
        var reqData = _objectSpread(_objectSpread({}, item), {}, {
          parentid: _this.$store.state.user.userInfo.id,
          "state": "NORMAL"
        });
        publishNotice(reqData).then(function (res) {
          if (res.code == '200') {
            _this.$alert(serverMsg(res.code)).then(function (res) {
              _this.getList(1);
            });
          } else {
            _this.$alert(serverMsg(res.code)).then(function (res) {});
          }
        }).catch();
      });
    },
    // 获取用户操作数据
    getList: function getList(pageNo) {
      var _this2 = this;
      this.loading = true;
      this.queryForm.pageNo = pageNo;
      var reqData = _objectSpread({}, this.queryForm);
      reqData.opid = this.queryForm.parentid;
      getNoticeList(reqData).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = _objectSpread({}, res.data);
        } else {
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.queryForm.pageNo = 1;
      this.getList(1);
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      this.getList(val);
    },
    // 新增公告
    addNotice: function addNotice() {
      this.editType = 0;
      this.dialog = true;
      this.noticeForm = _defineProperty(_defineProperty(_defineProperty({
        parentid: this.$store.state.user.userInfo.id,
        cnContent: '',
        // 中文内容
        hkContent: ''
      }, "cnContent", ''), "enContent", ''), "agents", ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"]);
    },
    // 发布
    // 停用
    stopHandle: function stopHandle(item) {
      var _this3 = this;
      this.$confirm(this.$i18n.t('settingNotice.alert_stop'), this.$i18n.t('settingNotice.alert_title'), {
        confirmButtonText: this.$i18n.t('settingNotice.form_quren'),
        cancelButtonText: this.$i18n.t('settingNotice.form_cancel'),
        type: 'warning'
      }).then(function () {
        var reqData = _objectSpread(_objectSpread({}, item), {}, {
          parentid: _this3.$store.state.user.userInfo.id,
          "state": "CLOSE"
        });
        disabledNotice(reqData).then(function (res) {
          if (res.code == '200') {
            _this3.$alert(serverMsg(res.code)).then(function (res) {
              _this3.getList(1);
            });
          } else {
            _this3.$alert(serverMsg(res.code)).then(function (res) {});
          }
        }).catch();
      });
    },
    // 删除
    delHandle: function delHandle(item) {
      var _this4 = this;
      this.$confirm(this.$i18n.t('settingNotice.alert_del'), this.$i18n.t('settingNotice.alert_title'), {
        confirmButtonText: this.$i18n.t('settingNotice.form_quren'),
        cancelButtonText: this.$i18n.t('settingNotice.form_cancel'),
        type: 'warning'
      }).then(function () {
        var reqData = _objectSpread(_objectSpread({}, item), {}, {
          parentid: _this4.$store.state.user.userInfo.id,
          "state": "DELETE"
        });
        deleteNotice(reqData).then(function (res) {
          if (res.code == '200') {
            _this4.$alert(serverMsg(res.code)).then(function (res) {
              _this4.getList(1);
            });
          } else {
            _this4.$alert(serverMsg(res.code)).then(function (res) {});
          }
        }).catch();
      });
    },
    // 编辑事件处理
    editHandle: function editHandle(item) {
      this.editType = 1;
      this.dialog = true;
      this.noticeForm = _objectSpread({}, item);
      this.editItem = _objectSpread({}, item);
      this.noticeForm.agents = item.agents.split(',');
    },
    // 新增
    addSubmit: function addSubmit() {
      var _this5 = this;
      if (this.noticeForm.agents.length < 0) {
        this.$alert(this.$i18n.t('settingNotice.alert_level'));
        return false;
      }
      this.$refs['noticeForm'].validate(function (valid) {
        if (valid) {
          var reqData = _objectSpread({}, _this5.noticeForm);
          reqData.agents = reqData.agents.join();
          saveNotice(reqData).then(function (res) {
            if (res.code == '200') {
              _this5.dialog = false;
              _this5.$alert(serverMsg(res.code)).then(function (res) {
                _this5.getList(1);
              });
            } else {
              _this5.$alert(serverMsg(res.code)).then(function (res) {});
            }
          }).catch();
        }
      });
    },
    // 编辑
    editSubmit: function editSubmit() {
      var _this6 = this;
      if (this.noticeForm.agents.length < 0) {
        this.$alert(this.$i18n.t('settingNotice.alert_level'));
        return false;
      }
      this.$refs['noticeForm'].validate(function (valid) {
        if (valid) {
          var reqData = _objectSpread({}, _this6.noticeForm);
          reqData.agents = reqData.agents.join();
          updateNotice(reqData).then(function (res) {
            if (res.code == '200') {
              _this6.dialog = false;
              _this6.$alert(serverMsg(res.code)).then(function (res) {
                _this6.getList(1);
              });
            } else {
              _this6.$alert(serverMsg(res.code)).then(function (res) {});
            }
          }).catch();
        }
      });
    },
    // 重设
    resetSubmit: function resetSubmit() {
      if (this.editType == 0) {
        this.noticeForm = _defineProperty(_defineProperty(_defineProperty({
          parentid: this.$store.state.user.userInfo.id,
          cnContent: '',
          // 中文内容
          hkContent: ''
        }, "cnContent", ''), "enContent", ''), "agents", ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"]);
      } else {
        this.noticeForm = _objectSpread({}, this.editItem);
        this.noticeForm.agents = this.editItem.agents.split(',');
      }
    },
    // 获取公告层级显示
    getTitle: function getTitle(title) {
      var _this7 = this;
      var s_arr = title.split(',');
      var fill_arr = [];
      var _loop = function _loop(i) {
        var filter_arr = _this7.options.filter(function (item) {
          return item.value == s_arr[i];
        });
        if (filter_arr.length >= 1) {
          fill_arr.push(filter_arr[0].label);
        }
      };
      for (var i = 0; i < s_arr.length; i++) {
        _loop(i);
      }
      return fill_arr.join(',');
    },
    // 弹窗关闭前清空校验
    handleClose: function handleClose() {
      this.dialog = false;
      this.$refs['noticeForm'].resetFields();
      this.$refs['noticeForm'].clearValidate();
    }
  }
};