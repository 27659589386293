import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { percentage } from '@/utils/big';
import { mapGetters } from 'vuex';
import Logo from "./Logo";
import _variables from '@/assets/styles/variables.scss';
import { userMenus } from '@/api/account/user';
export default {
  components: {
    Logo: Logo
  },
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function data() {
    return {
      percentage: percentage,
      active: 0,
      isUnfold: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routers', 'langType', 'userInfo'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if (meta.activeMenu) {
      //     return meta.activeMenu;
      // }
      return path;
    },
    variables: function variables() {
      return _variables;
    }
  }),
  created: function created() {
    // 获取用户菜单
    this.setMenus();
  },
  mounted: function mounted() {},
  methods: {
    // 获取菜单
    setMenus: function setMenus() {
      var _this = this;
      userMenus().then(function (res) {
        if (res.code == 200) {
          var menus = res.data.menus;
          _this.$store.dispatch('clearRouters');
          _this.$store.dispatch('GenerateRoutes', _this.addCodeProperty(menus));
          _this.$store.dispatch('setPermission', _this.deepTreeAll(menus, [])); //设置权限指令
          _this.$store.dispatch('setIsAdmin', res.data.isAdmin); //设置权限指令
        }
      });
    },
    deepTreeAll: function deepTreeAll(data) {
      var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      for (var i = 0; i < data.length; i++) {
        var item = data[i];
        arr.push(item.perms);
        if (item.children && item.children.length) {
          this.deepTreeAll(item.children, arr);
        }
      }
      return arr;
    },
    addCodeProperty: function addCodeProperty(tree) {
      var _this2 = this;
      return tree.map(function (node) {
        var code = node.url ? "menusList.".concat(node.url.replace(/[\/-]/g, '')) : '';

        // const tranlateCode = this.$i18n.t(`${code}`);
        var tranlateCode = node.name;
        var newNode = _objectSpread(_objectSpread({}, node), {}, {
          code: tranlateCode
        });
        if (node.children) {
          newNode.children = _this2.addCodeProperty(node.children, code + '-');
        }
        return newNode;
      });
    }
  }
};