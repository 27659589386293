var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search-content" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.queryForm, inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("systemRole.search_name") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("systemRole.search_placeholder"),
                    },
                    on: {
                      input: function ($event) {
                        _vm.queryForm.name = _vm.queryForm.name.replace(
                          /\s/g,
                          ""
                        )
                      },
                    },
                    model: {
                      value: _vm.queryForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "name", $$v)
                      },
                      expression: "queryForm.name",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-self-search",
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("systemRole.search_btn")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "50px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "roleId",
              label: _vm.$t("systemRole.list_id"),
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roleName",
              label: _vm.$t("systemRole.form_name"),
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roleTag",
              label: _vm.$t("systemRole.form_code"),
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "remark", label: _vm.$t("systemRole.form_des") },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              width: "200",
              label: _vm.$t("systemRole.list_time"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.timeFormatter(scope.row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("systemRole.caozuo"),
              width: "120px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "btn-self-primary",
                        on: {
                          click: function ($event) {
                            return _vm.editRole(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("systemRole.detail")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog,
            title: _vm.$t("systemRole.detail"),
            width: "620px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.roleForm,
                size: "small",
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemRole.form_name"),
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.roleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "name", $$v)
                      },
                      expression: "roleForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemRole.form_code"),
                    prop: "code",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.roleForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "code", $$v)
                      },
                      expression: "roleForm.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemRole.form_per"),
                    prop: "menuIds",
                  },
                },
                [
                  _c("el-tree", {
                    ref: "menuListTree",
                    attrs: {
                      "node-key": "menuId",
                      data: _vm.options,
                      "show-checkbox": "",
                      props: _vm.defaultProps,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemRole.form_des"),
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: true, rows: "5", type: "textarea" },
                    model: {
                      value: _vm.roleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "remark", $$v)
                      },
                      expression: "roleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("systemRole.form_sure")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }