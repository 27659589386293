import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getcrypt, encryptWithRsa } from '@/utils/rsaEncrypt';
import { setToken } from '@/utils/auth';
import { getCodeImg, login } from '@/api/login';
import Cookies from 'js-cookie';
import device from '@/layout/index';
import { mapGetters } from 'vuex';
export default {
  name: 'Login',
  mixins: [device],
  data: function data() {
    return {
      lang: 'zh',
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        verifyCode: ''
      },
      loading: false,
      options: [{
        value: 'zh',
        label: '中文简体'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'th',
        label: 'ภาษาไทย'
      }, {
        value: 'vi',
        label: 'Tiếng Việt'
      }, {
        value: 'ko',
        label: '한국어'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['langType'])), {}, {
    showLang: {
      get: function get() {
        return this.langType;
      },
      set: function set() {}
    }
  }),
  created: function created() {
    //  获取验证码
    this.getCode();
    // 获取用户名密码等Cookie
    this.getCookie();
  },
  mounted: function mounted() {
    window.addEventListener('keyup', this.handleEnterKey);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keyup', this.handleEnterKey);
  },
  methods: {
    getCookie: function getCookie() {
      var username = Cookies.get('_DxdK_xvVQ'); // 用户名
      var password = Cookies.get('Fvbp_ZtQ'); // 密码

      this.loginForm = {
        username: username === undefined ? '' : getcrypt(username),
        password: password === undefined ? '' : getcrypt(password),
        code: ''
      };
    },
    handleEnterKey: function handleEnterKey(e) {
      if (e.code && e.code == 'Enter') {
        this.handleLogin();
      }
    },
    getCode: function getCode() {
      var _this = this;
      getCodeImg().then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res], {
          type: 'image/jpeg'
        }));
        _this.codeUrl = url;
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      if (!this.loginForm.verifyCode) {
        this.$message.error(this.$t('login.code_name'));
        return false;
      }
      if (!this.loginForm.username) {
        this.$message.error(this.$t('login.tips_name'));
        return false;
      }
      if (!this.loginForm.password) {
        this.$message.error(this.$t('login.tips_pwd'));
        return false;
      }
      var user = {
        username: this.loginForm.username,
        password: encryptWithRsa(this.loginForm.password),
        verifyCode: this.loginForm.verifyCode
      };
      this.loading = true;
      login(user).then(function (res) {
        if (res.code == 200) {
          setToken(res.data.token, true);
          Cookies.remove('username');
          Cookies.remove('password');
          _this2.$store.dispatch('setUserInfo', res.data.userInfo);
          _this2.loading = false;
          _this2.$router.push({
            path: '/'
          });
        } else {
          _this2.loading = false;
          _this2.getCode();
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.getCode();
      });
    },
    // 语言选择
    langChange: function langChange(type) {
      this.$i18n.locale = type;
      localStorage.setItem('lang', type);
      this.$store.dispatch('setLang', type);
    }
  }
};