import request from '@/utils/request';

// 获取菜单树形列表
export function getMenusTree(id) {
  return request({
    url: "/as/system/menu/tree?parentId=".concat(id),
    method: 'GET'
  });
}
export function getMenus(data) {
  return request({
    url: '/as/system/menu/query',
    method: 'post',
    data: data
  });
}