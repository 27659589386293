import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { homeNotice } from '@/api/notice';
import { getDay } from '@/utils/time';
export default {
  name: 'Dashboard',
  data: function data() {
    return {
      list: []
    };
  },
  computed: _objectSpread({}, mapGetters(['userInfo'])),
  mounted: function mounted() {},
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    getNotice: function getNotice() {
      var _this = this;
      var agents = this.userInfo.userType == 'PLATFORM' ? ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"] : [this.userInfo.userType];
      var reqData = {
        "parentid": this.userInfo.id,
        "pageNo": 1,
        "pageSize": 100,
        "startdate": getDay(-91),
        "enddate": getDay(1),
        "state": 'NORMAL',
        "opid": this.userInfo.id,
        "agents": agents,
        "visible": 1
      };
      homeNotice(reqData).then(function (res) {
        if (res.code == 200) {
          _this.list = res.data.list;
        }
      });
    }
  }
};