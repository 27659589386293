import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import { showLoading, hideLoading } from '@/utils/loading';
import Config from '@/settings';
import errCode from "./errCode";
import i18n from "../lang";
function getLanguage() {
  return localStorage.getItem('lang') || 'zh'; // set locale
}
var showMsg = 0;
// 创建axios实例
var service = axios.create({
  withCredentials: true,
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/as', // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});
axios.defaults.withCredentials = true;
// request拦截器
service.interceptors.request.use(function (config) {
  var reqUrl = config.url.split('/');
  if (process.env.NODE_ENV == 'production') {
    config.baseURL = process.env.VUE_APP_BASE_API;
  } else if (reqUrl[1] == 'as') {
    config.baseURL = '/as';
  } else if (reqUrl[1] == 'report') {
    config.baseURL = '/report';
  }
  if (getToken()) {
    config.headers['Authorization'] = "Bearer ".concat(getToken());
  }
  if (config.showLoading) {
    showLoading();
  }
  config.headers['Content-Type'] = ' application/json';
  // config.headers.common['lang'] = 'zh-EN';
  config.headers.common['lang'] = getLanguage();
  return config;
}, function (error) {
  hideLoading();
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  hideLoading();
  var code = response.status;
  if (code < 200 || code > 300) {
    return Promise.reject(response.message);
  } else {
    //  未登录或者登录状态失效
    if (response.data.code == '401' || response.data.code == '10001' || response.data.code == '10002') {
      showMsg += 1;
      if (showMsg == 1) {
        MessageBox({
          title: i18n.tc('温馨提示'),
          message: i18n.tc('登录失效,请重新登录')
        }).then(function () {
          showMsg = 0;
          store.dispatch('initTab');
          store.dispatch('clearRouters');
          store.dispatch('LoginInvalid').then(function () {
            location.reload();
          });
        }).catch(function () {
          showMsg = 0;
        });
      }
    } else if (response.data.code && errCode.toString().includes(response.data.code)) {
      Message.error(i18n.tc(response.data.message));
      return Promise.reject(response.data.message);
    } else {
      return response.data;
    }
  }
}, function (error) {
  hideLoading();
  var errorRes = error.response;
  var msg = errorRes.data.message ? errorRes.data.message : i18n.tc('操作失败,请稍后重试');
  var code = 0;
  try {
    code = errorRes.data.code;
    if (code == '401') {
      showMsg += 1;
      if (showMsg == 1) {
        MessageBox({
          title: i18n.tc('温馨提示'),
          message: i18n.tc(msg)
        }).then(function () {
          showMsg = 0;
          store.dispatch('initTab');
          store.dispatch('clearRouters');
          store.dispatch('LoginInvalid').then(function () {
            location.reload();
          }).catch(function () {
            showMsg = 0;
          });
          ;
        });
      }
    } else if (code == 4011) {
      MessageBox({
        title: i18n.tc('温馨提示'),
        message: i18n.tc(msg)
      }).then(function () {
        showMsg = 0;
        store.dispatch('initTab');
        store.dispatch('clearRouters');
        store.dispatch('LogOut').then(function () {
          location.reload();
        }).catch(function () {
          showMsg = 0;
        });
        ;
      });
    } else if (response.data.code && errCode.includes(response.data.code)) {
      Message.error(i18n.tc(msg));
    } else {
      Message.error(i18n.tc(msg));
    }
    return Promise.reject(errorRes);
  } catch (e) {
    Message.error(i18n.tc(msg));
    return Promise.reject(errorRes);
  }
});
export default service;