import request from '@/utils/request';

// 代理权限设定
export function setPer(data) {
  return request({
    url: '/as/account/user/menu',
    method: 'POST',
    data: data,
    showLoading: true
  });
}

// 用户解锁
export function userUnLocked(data) {
  return request({
    url: '/account/user/unlocked',
    method: 'post',
    data: data
  });
}

// 获取用户菜单
export function userMenus(data) {
  return request({
    url: '/yx/getMenus',
    method: 'post',
    data: data
  });
}