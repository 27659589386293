import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.string.pad-start";
var oneDayTime = 1000 * 60 * 60 * 24;

// 查询最近两日的日期
export var currentTimeByBJ = function currentTimeByBJ() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var tYear = currentTime.getFullYear();
  var tMonth = currentTime.getMonth() + 1;
  var tDate = currentTime.getDate();
  var tHours = currentTime.getHours();
  var tMinutes = currentTime.getMinutes();
  var tSecond = currentTime.getSeconds();
  tMonth = doHandleMonth(tMonth);
  tDate = doHandleMonth(tDate);
  tHours = doHandleMonth(tHours);
  tMinutes = doHandleMonth(tMinutes);
  tSecond = doHandleMonth(tSecond);
  return "".concat(tYear, "-").concat(tMonth, "-").concat(tDate, " ").concat(tHours, ":").concat(tMinutes, ":").concat(tSecond);
};

// 查询最近两日的日期
export var lastTwoDays = function lastTwoDays() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  // 没有过12点
  if (hour < 12) {
    s_milliseconds = currentTime.getTime() - oneDayTime * 1;
    e_milliseconds = currentTime.getTime() - oneDayTime * 0; // 没过12点 结束日期就是今日
  } else {
    s_milliseconds = currentTime.getTime() + oneDayTime * 0;
    e_milliseconds = currentTime.getTime() + oneDayTime * 1; //过了12点，结束日期往后推一天
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 时间转换
export var timeConvert = function timeConvert(time) {
  var date = new Date(time);
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
};

// 日期限制
export var limit = function limit() {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth);
  tDate = doHandleMonth(tDate);
  return [tYear, tMonth, tDate];
};
export var doHandleMonth = function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
};

// 查询最近7天
export var lastWeekDays = function lastWeekDays() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  // 没有过12点
  if (hour < 12) {
    s_milliseconds = currentTime.getTime() - oneDayTime * 7;
    e_milliseconds = currentTime.getTime() - oneDayTime * 0; // 没过12点 结束日期就是今日
  } else {
    s_milliseconds = currentTime.getTime() - oneDayTime * 6;
    e_milliseconds = currentTime.getTime() + oneDayTime * 1; // 过了12点，结束日期往后推一天
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 查询昨日日期
export var yesterDay = function yesterDay() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  // 没有过12点
  if (hour < 12) {
    s_milliseconds = currentTime.getTime() - oneDayTime * 2;
    e_milliseconds = currentTime.getTime() - oneDayTime * 1; // 没过12点 结束日期就是今日
  } else {
    s_milliseconds = currentTime.getTime() - oneDayTime * 1;
    e_milliseconds = currentTime.getTime() - oneDayTime * 0; // 过了12点，结束日期往后推一天
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 查询本周日期
export var crrentWeek = function crrentWeek() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var whichDay = currentTime.getDay(); // 今天是周几
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  if (whichDay == 0) {
    s_milliseconds = currentTime.getTime() - 6 * oneDayTime; //周一
  } else {
    s_milliseconds = currentTime.getTime() - (whichDay - 1) * oneDayTime; //周一
  }
  // e_milliseconds = currentTime.getTime() + 1 * oneDayTime;//显示周几 往后面推一天

  e_milliseconds = hour > 12 ? currentTime.getTime() + 1 * oneDayTime : currentTime.getTime() + 0 * oneDayTime; //显示周几 过了12点往后推一天

  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 查询上周日期
export var lastWeek = function lastWeek() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var whichDay = currentTime.getDay(); // 今天是周几
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  if (whichDay == 0) {
    s_milliseconds = currentTime.getTime() - 13 * oneDayTime; //周一
    e_milliseconds = currentTime.getTime() - 6 * oneDayTime; // 往后推一天
  } else {
    s_milliseconds = currentTime.getTime() - (whichDay + 6) * oneDayTime; //周一
    e_milliseconds = currentTime.getTime() - (whichDay - 1) * oneDayTime; // 当前星期的周一
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 查询今日日期
export var toDay = function toDay() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  // 没有过12点
  if (hour < 12) {
    s_milliseconds = currentTime.getTime() - oneDayTime * 1;
    e_milliseconds = currentTime.getTime() - oneDayTime * 0; // 没过12点 结束日期就是今日
  } else {
    s_milliseconds = currentTime.getTime() + oneDayTime * 0;
    e_milliseconds = currentTime.getTime() + oneDayTime * 1; // 过了12点，结束日期往后推一天
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 查询本月的日期
export var currentMonth = function currentMonth() {
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var currentYear = currentTime.getFullYear(); // 当前时间年
  var currentMonth = parseInt(currentTime.getMonth()); // 当前时间月

  var lastMonth = (currentMonth - 1 + 12) % 12 + 1; // 上月月份
  var lastYear = currentMonth + 1 - 1 <= 0 ? currentTime.getFullYear() - 1 : currentTime.getFullYear(); // 上月年份

  var currentDay = currentTime.getDate(); // 当前时间 日
  var beginDate = new Date("".concat(currentYear, "/").concat(doHandleMonth(currentMonth + 1), "/01")); //  当月1号
  var lastBeginMonth = new Date("".concat(lastYear, "/").concat(doHandleMonth(lastMonth), "/01")); //  上月1号
  var beginDay = beginDate.getDay(); // 获取本月1号是周几
  var lastBeginDay = lastBeginMonth.getDay(); // 获取上月1号是周几

  var diffDay = 0;
  var lastDiffDay = 0;
  diffDay = beginDay == 0 ? 1 : beginDay == 1 ? 0 : 8 - beginDay;
  lastDiffDay = lastBeginDay == 0 ? 1 : lastBeginDay == 1 ? 0 : 8 - lastBeginDay;
  var startDay = new Date(beginDate.getTime() + diffDay * oneDayTime); // 每月开始日期
  var startDay_date = startDay.getDate();

  // 当前日期大于 每月 月初 周一 日期
  if (currentDay >= startDay_date) {
    s_milliseconds = beginDate.getTime() + diffDay * oneDayTime; // 开始时间 本月周一
    e_milliseconds = currentTime.getTime() + oneDayTime * 1; // 结束时间  当前时间
  } else {
    s_milliseconds = lastBeginMonth.getTime() + lastDiffDay * oneDayTime; // 开始时间  上个月周一日期
    e_milliseconds = currentTime.getTime() + oneDayTime * 1; // 结束时间  当前时间
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};

// 获取今日时间 区间 
export var singleToDay = function singleToDay() {
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var hour = currentTime.getHours();
  var s_milliseconds = ''; //开始时间毫秒数

  // 没有过12点
  if (hour < 12) {
    s_milliseconds = currentTime.getTime() - oneDayTime * 1;
  } else {
    s_milliseconds = currentTime.getTime();
  }
  return [timeConvert(s_milliseconds), timeConvert(s_milliseconds)];
};

// 获取一周前的时间
export var recentWeek = function recentWeek() {
  var currentDay = isToday() ? -7 : -6;
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * currentDay;
  today.setTime(targetday_milliseconds);
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};

// 获取年月日
export var getYmd = function getYmd(time) {
  return time.substr(0, 10);
};

// 毫秒数转年月日时分秒
export var formatTime = function formatTime(milliseconds) {
  if (milliseconds == 0) {
    return '';
  }
  var date = new Date(milliseconds);
  var year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, '0');
  var day = String(date.getDate()).padStart(2, '0');
  var hours = String(date.getHours()).padStart(2, '0');
  var minutes = String(date.getMinutes()).padStart(2, '0');
  var seconds = String(date.getSeconds()).padStart(2, '0');
  var formattedTime = "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
  return formattedTime;
};

/**
   * 获取截止日期
   * @param {*} day 
   */
export var expiredDay = function expiredDay(day) {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};

/**
   * 获取最近几天天日期
   * @param {*} day 
   */
export var getDay = function getDay(day) {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds);
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};

// 查询上月的日期
export var lastMonth = function lastMonth() {
  var s_milliseconds = ''; //开始时间毫秒数
  var e_milliseconds = ''; //结束时间毫秒数
  var currentTime = new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000); // 当前时间 - 北京时间
  var currentYear = currentTime.getFullYear(); // 当前时间年
  var currentMonth = parseInt(currentTime.getMonth()); // 当前时间月

  var lastMonth = (currentMonth - 1 + 12) % 12 + 1; // 上月月份
  var lastYear = currentMonth + 1 - 1 <= 0 ? currentTime.getFullYear() - 1 : currentTime.getFullYear(); // 上月年份

  var twoLastMonth = (currentMonth - 2 + 12) % 12 + 1;
  var twoLastYear = currentMonth + 1 - 2 <= 0 ? currentTime.getFullYear() - 1 : currentTime.getFullYear(); // 上上年份

  var currentDay = currentTime.getDate(); // 当前时间日
  var beginDate = new Date("".concat(currentYear, "/").concat(doHandleMonth(currentMonth + 1), "/01")); //  当月1号
  var lastBeginMonth = new Date("".concat(lastYear, "/").concat(doHandleMonth(lastMonth), "/01")); //  上月1号
  var twoMonthsBefore = new Date("".concat(twoLastYear, "/").concat(doHandleMonth(twoLastMonth), "/01")); // 上上月1号
  var beginDay = beginDate.getDay(); // 获取本月1号是周几
  var lastBeginDay = lastBeginMonth.getDay(); // 获取上月1号是周几
  var twoMonthsBeforeDay = twoMonthsBefore.getDay(); // 获取上上月1号是周几

  var diffDay = beginDay == 0 ? 1 : beginDay == 1 ? 0 : 8 - beginDay; //本月1号 距离周一多少天  本月周一 日期
  var lastDiffDay = lastBeginDay == 0 ? 1 : lastBeginDay == 1 ? 0 : 8 - lastBeginDay; //上月 月1号 距离周一多少天  上月月周一日期
  var twoMonthsBeforeDiffDay = twoMonthsBeforeDay == 0 ? 1 : twoMonthsBeforeDay == 1 ? 0 : 8 - twoMonthsBeforeDay; // 上上月 月1号 距离周一多少天  上上月周一日期

  var startDay = new Date(beginDate.getTime() + diffDay * oneDayTime); // 每月开始日期
  var startDay_date = startDay.getDate();

  // 当前日期大于 每月 月初 周一 日期
  if (currentDay >= startDay_date) {
    s_milliseconds = lastBeginMonth.getTime() + lastDiffDay * oneDayTime; // 上月开始日期 周一
    e_milliseconds = beginDate.getTime() + diffDay * oneDayTime; // 本月开始 周一
  } else {
    s_milliseconds = twoMonthsBefore.getTime() + twoMonthsBeforeDiffDay * oneDayTime; // 上上月 开始日期 周一
    e_milliseconds = lastBeginMonth.getTime() + lastDiffDay * oneDayTime; //  上月 周一日期
  }
  return [timeConvert(s_milliseconds), timeConvert(e_milliseconds)];
};