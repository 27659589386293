import "core-js/modules/es6.array.sort";
export function setPwd() {
  var num_chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var en_chars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  var result = [];
  for (var i = 0; i < 4; i++) {
    var num_random = num_chars[Math.floor(Math.random() * num_chars.length)];
    var en_random = en_chars[Math.floor(Math.random() * en_chars.length)];
    result.push(num_random);
    result.push(en_random);
  }
  ;
  result.sort(function () {
    return Math.random() - 0.5;
  });
  return result.reverse().join('');
}