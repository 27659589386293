var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "update-content" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticStyle: { display: "block", margin: "0 auto" },
            attrs: { model: _vm.passWordForm, rules: _vm.rules, size: "small" },
          },
          [
            _c("el-form-item", { attrs: { prop: "oldPassword" } }, [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("updatePwd.jdlma")) + ":")]),
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      type: "password",
                      "auto-complete": "off",
                      placeholder: _vm.$t("updatePwd.jdlma"),
                    },
                    model: {
                      value: _vm.passWordForm.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.passWordForm, "oldPassword", $$v)
                      },
                      expression: "passWordForm.oldPassword",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "form-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("updatePwd.xmm")) + ":")]),
              _c("div", { staticClass: "form-item-row" }, [
                _c(
                  "div",
                  { staticStyle: { width: "350px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "newPassword" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: _vm.$t("updatePwd.xmm"),
                          },
                          model: {
                            value: _vm.passWordForm.newPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.passWordForm, "newPassword", $$v)
                            },
                            expression: "passWordForm.newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "random-content",
                    staticStyle: { width: "250px", "margin-left": "20px" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: _vm.$t("updatePwd.suijimm") },
                      model: {
                        value: _vm.passWordForm.random,
                        callback: function ($$v) {
                          _vm.$set(_vm.passWordForm, "random", $$v)
                        },
                        expression: "passWordForm.random",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "random", on: { click: _vm.setPwd } },
                      [_vm._v(_vm._s(_vm.$t("updatePwd.suijimm")))]
                    ),
                  ],
                  1
                ),
                _c("p", [_vm._v(_vm._s(_vm.$t("updatePwd.mmbit")))]),
              ]),
            ]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("updatePwd.qrmm")) + ":")]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "confirmPass" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: {
                        type: "password",
                        "auto-complete": "off",
                        placeholder: _vm.$t("updatePwd.qrmm"),
                      },
                      model: {
                        value: _vm.passWordForm.confirmPass,
                        callback: function ($$v) {
                          _vm.$set(_vm.passWordForm, "confirmPass", $$v)
                        },
                        expression: "passWordForm.confirmPass",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "btn-footer" }, [
          _c("div", { staticClass: "reset", on: { click: _vm.resetForm } }, [
            _vm._v(_vm._s(_vm.$t("updatePwd.chohshe"))),
          ]),
          _c("div", { staticClass: "sure", on: { click: _vm.doSubmit } }, [
            _vm._v(_vm._s(_vm.$t("updatePwd.queren"))),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }