import "core-js/modules/es6.regexp.match";
import "core-js/modules/web.dom.iterable";
var req = require.context('../../assets/icons/svg', false, /\.svg$/);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys();
};
var re = /\.\/(.*)\.svg/;
var icons = requireAll(req).map(function (i) {
  return i.match(re)[1];
});
export default icons;