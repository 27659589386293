export default {
  home: {
    "homePage": "首頁"
  },
  // 登录界面
  login: {
    title: '代理管理网',
    tips: '账密登录',
    name: '账号',
    passWord: '密码',
    remember: '记住我',
    languageSelection: '语言选择',
    loginBtn: '登录',
    loading: '登录中...'
  },
  //   报表管理 用户操作记录
  operationInfo: {
    date: '日期',
    accountType: '賬號類型',
    optionsType: '操作類型',
    optionsName: '操作賬號',
    search: '搜索',
    t_loginname: '賬號',
    t_usertype: '賬號類型',
    t_optype: '操作類型',
    t_operation: '操作詳情',
    t_opname: '操作賬號',
    t_operationdate: '日期'
  },
  // 报表管理-点数记录
  accountChange: {
    date: '日期',
    gameType: '遊戲類型',
    account: '賬號',
    search_btn: '搜索',
    export_btn: '導出',
    table_1: '交易編號',
    table_2: '交易日期',
    table_3: '賬號',
    table_4: '用戶別名',
    table_5: '遊戲類型',
    table_6: '操作',
    table_7: '交易金額',
    table_8: '主錢包總金額',
    table_9: '別注',
    table_10: '操作賬號'
  }
};