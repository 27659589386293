import request from '@/utils/request';

// 登录
export function login(data) {
  return request({
    url: '/yx/login',
    method: 'post',
    // headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: data
  });
}

// 获取图片验证码
export function getCodeImg() {
  return request({
    url: '/yx/getVerifyCode',
    method: 'get',
    responseType: 'arraybuffer'
  });
}

//登出
export function logout() {
  return request({
    url: '/yx/logout',
    method: 'get',
    showLoading: true
  });
}

//修改登录密码
export function updatePassWord(params) {
  return request({
    url: "/yx/changePwd",
    method: 'get',
    params: params,
    showLoading: true
  });
}

// 获取余额
export function getBalance() {
  return request({
    url: '/yx/getBalance',
    method: 'get'
  });
}