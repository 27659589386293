import "/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.promise.js";
import "/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.object.assign.js";
import "/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 权限指令
import permission from "./components/Permission";
import "./assets/styles/index.scss";
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons";
import "./router/index";
import _i18n from "./lang";
import echarts from 'echarts';
Vue.prototype.$EventBus = new Vue();
Vue.use(_i18n);
Vue.use(permission);
Vue.use(Element, {
  size: Cookies.get('size') || 'small',
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
new Vue({
  i18n: _i18n,
  el: '#app',
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');