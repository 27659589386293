import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);
import Login from '@/views/login'; // 登录页面
import NoPage from '@/views/features/404'; // 404页面
import Unauthorized from '@/views/features/401'; // 401页面
import Redirect from '@/views/features/401'; // 重定向页面
import Home from '@/views/home'; // 首页
import Roles from '@/views/system/role/index.vue'; //角色管理
import Menus from '@/views/system/menu/index.vue'; //菜单管理
import Notice from '@/views/setting/notice/index.vue'; // 通告管理
import UpdatePass from '@/views/user/updatePass.vue'; // 修改密码

var channelMng = function channelMng() {
  return import('@/views/account/channelMng/index.vue');
}; // 账号管理-渠道管理
var agencyMng = function agencyMng() {
  return import('@/views/account/agencyMng/index.vue');
}; // 账号管理-代理管理
var subUserList = function subUserList() {
  return import('@/views/account/subUserList/index.vue');
}; // 账号管理-子账号管理
var userMng = function userMng() {
  return import('@/views/account/userMng/index.vue');
}; // 账号管理-用户管理
var blackListMng = function blackListMng() {
  return import('@/views/account/blackListMng/index.vue');
}; // 账号管理-IP黑名单

var exchangeRate = function exchangeRate() {
  return import('@/views/report/exchangeRate/index.vue');
}; // 报表查询-汇率查询
var transferInquiry = function transferInquiry() {
  return import('@/views/report/transferInquiry/index.vue');
}; // 报表查询-转账查询
var blockchainBetting = function blockchainBetting() {
  return import('@/views/report/blockchainBetting/index.vue');
}; // 报表查询-区块链注单查询
var winReport = function winReport() {
  return import('@/views/report/winReport/index.vue');
}; // 报表查询-输赢报表
var tableWinReport = function tableWinReport() {
  return import('@/views/report/tableWinReport/index.vue');
}; // 报表查询-台桌输赢报表
var gameRecord = function gameRecord() {
  return import('@/views/report/gameRecord/index.vue');
}; // 报表查询-游戏记录查询
var vgGameRecord = function vgGameRecord() {
  return import('@/views/report/vgGameRecord/index.vue');
}; // 报表查询-棋牌捕鱼游戏记录查询
var liveGameRecord = function liveGameRecord() {
  return import('@/views/report/liveGameRecord/index.vue');
}; // 报表查询-直播游戏记录查询
var liveGiftInquiry = function liveGiftInquiry() {
  return import('@/views/report/liveGiftInquiry/index.vue');
}; // 报表查询-直播礼物查询
var gameResultsReport = function gameResultsReport() {
  return import('@/views/report/gameResultsReport/index.vue');
}; // 报表查询-游戏结果报表
var playerBettingAnalysis = function playerBettingAnalysis() {
  return import('@/views/report/playerBettingAnalysis/index.vue');
}; // 报表查询-玩家投注分析
var userInformation = function userInformation() {
  return import('@/views/report/userInformation/index.vue');
}; // 报表查询-用户信息查询
var userAssociatedIP = function userAssociatedIP() {
  return import('@/views/report/userAssociatedIP/index.vue');
}; // 报表查询-用户关联IP查询
var sameIPUsers = function sameIPUsers() {
  return import('@/views/report/sameIPUsers/index.vue');
}; // 报表查询-同IP用户查询
var newGameRecord = function newGameRecord() {
  return import('@/views/report/newGameRecord/index.vue');
}; // 报表查询-新游戏记录查询
var machinePrinterConfig = function machinePrinterConfig() {
  return import('@/views/report/machinePrinterConfig/index.vue');
}; // 报表查询-机打配置表
var machineBlacklist = function machineBlacklist() {
  return import('@/views/report/machineBlacklist/index.vue');
}; // 报表查询-机打黑名单查询
var betReportList = function betReportList() {
  return import('@/views/report/betReportList/index.vue');
}; // 报表查询-投注记录
var betFailReport = function betFailReport() {
  return import('@/views/report/betFailReport/index.vue');
}; // 报表查询-无缝钱包退款

export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '首页',
    noCache: true
  },
  component: Login,
  hidden: true
}, {
  path: '/404',
  component: NoPage,
  hidden: true
}, {
  path: '/401',
  component: Unauthorized,
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: Redirect
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: Home,
    name: 'Home',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/system',
  component: Layout,
  meta: {
    title: '菜单管理'
  },
  redirect: 'noredirect',
  children: [{
    path: 'role',
    component: Roles,
    name: 'Roles',
    meta: {
      title: '角色管理'
    }
  }, {
    path: 'menu',
    component: Menus,
    name: 'Menus',
    meta: {
      title: '菜单管理'
    }
  }]
}, {
  path: '/setting',
  component: Layout,
  meta: {
    title: '系统配置'
  },
  redirect: 'noredirect',
  children: [{
    path: 'notice',
    component: Notice,
    name: 'Notice',
    meta: {
      title: '通告管理'
    }
  }]
}, {
  path: '/user',
  component: Layout,
  meta: {
    title: '用户管理'
  },
  redirect: 'noredirect',
  children: [{
    path: 'updatePass',
    component: UpdatePass,
    name: 'UpdatePass',
    meta: {
      title: '修改密码'
    }
  }]
}, {
  path: '/account',
  component: Layout,
  meta: {
    title: '账号管理',
    icon: 'index'
    // activeMenu: true
  },
  redirect: 'noredirect',
  children: [{
    path: '/account/channelMng',
    component: channelMng,
    name: 'channelMng',
    meta: {
      title: '渠道管理'
    }
  }, {
    path: '/account/agencyMng',
    component: agencyMng,
    name: 'agencyMng',
    meta: {
      title: '代理管理'
    }
  }, {
    path: '/account/subUserList',
    component: subUserList,
    name: 'subUserList',
    meta: {
      title: '子账号管理'
    }
  }, {
    path: '/account/userMng',
    component: userMng,
    name: 'userMng',
    meta: {
      title: '用户管理'
    }
  }, {
    path: '/account/blackListMng',
    component: blackListMng,
    name: 'blackListMng',
    meta: {
      title: 'IP黑名单管理'
    }
  }]
}, {
  path: '/report',
  component: Layout,
  meta: {
    title: '报表查询',
    icon: 'index'
  },
  redirect: 'noredirect',
  children: [{
    path: '/report/exchangeRate',
    component: exchangeRate,
    name: 'exchangeRate',
    meta: {
      title: '汇率查询'
    }
  }, {
    path: '/report/transferInquiry',
    component: transferInquiry,
    name: 'transferInquiry',
    meta: {
      title: '转账查询'
    }
  }, {
    path: '/report/blockChainReport',
    component: blockchainBetting,
    name: 'blockchainBetting',
    meta: {
      title: '区块链注单查询'
    }
  }, {
    path: '/report/winReport',
    component: winReport,
    name: 'winReport',
    meta: {
      title: '输赢报表'
    }
  }, {
    path: '/report/tableWinReport',
    component: tableWinReport,
    name: 'tableWinReport',
    meta: {
      title: '桌台输赢报表'
    }
  }, {
    path: '/report/gameRecord',
    component: gameRecord,
    name: 'gameRecord',
    meta: {
      title: '游戏记录查询'
    }
  }, {
    path: '/report/gameLogVG',
    component: vgGameRecord,
    name: 'vgGameRecord',
    meta: {
      title: '棋牌捕鱼游戏记录查询'
    }
  }, {
    path: '/report/liveGameRecord',
    component: liveGameRecord,
    name: 'liveGameRecord',
    meta: {
      title: '直播游戏记录查询'
    }
  }, {
    path: '/report/liveGiftInquiry',
    component: liveGiftInquiry,
    name: 'liveGiftInquiry',
    meta: {
      title: '直播礼物查询'
    }
  }, {
    path: '/report/gameResultsReport',
    component: gameResultsReport,
    name: 'gameResultsReport',
    meta: {
      title: '游戏结果报表'
    }
  }, {
    path: '/report/playerBettingAnalysis',
    component: playerBettingAnalysis,
    name: 'playerBettingAnalysis',
    meta: {
      title: '玩家投注分析'
    }
  }, {
    path: '/report/userInformation',
    component: userInformation,
    name: 'userInformation',
    meta: {
      title: '用户信息查询'
    }
  }, {
    path: '/report/userAssociatedIP',
    component: userAssociatedIP,
    name: 'userAssociatedIP',
    meta: {
      title: '用户关联IP查询'
    }
  }, {
    path: '/report/sameIPUsers',
    component: sameIPUsers,
    name: 'sameIPUsers',
    meta: {
      title: '同IP用户查询'
    }
  }, {
    path: '/report/newGameRecord',
    component: newGameRecord,
    name: 'newGameRecord',
    meta: {
      title: '新游戏记录查询'
    }
  }, {
    path: '/report/machinePrinterConfig',
    component: machinePrinterConfig,
    name: 'machinePrinterConfig',
    meta: {
      title: '机打配置表'
    }
  }, {
    path: '/report/machineBlacklist',
    component: machineBlacklist,
    name: 'machineBlacklist',
    meta: {
      title: '机打黑名单查询'
    }
  }, {
    path: '/report/betReport',
    component: betReportList,
    name: 'betReportList',
    meta: {
      title: '注点查询'
    }
  }, {
    path: '/report/betFailReport',
    component: betFailReport,
    name: 'betFailReport',
    meta: {
      title: '无缝钱包异常注单列表'
    }
  }]
}, {
  path: '/dataSourceMonitoring',
  component: Layout,
  meta: {
    title: '数据源监控',
    icon: 'index'
  },
  redirect: 'noredirect',
  children: []
}];
export default new Router({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});