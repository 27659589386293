//
//
//
//
//
//
//
//
//
//
//
//
//

import { AppMain, Navbar, Sidebar, TagsView } from "./components";
export default {
  name: "Layout",
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  data: function data() {
    return {
      isCollapse: false
    };
  },
  mounted: function mounted() {},
  methods: {
    changeCol: function changeCol(val) {
      this.isCollapse = val;
    }
  }
};