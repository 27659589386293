var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slide-bar-content" },
    [
      _c(
        "el-scrollbar",
        [
          _c("logo", { attrs: { isCollapse: _vm.isCollapse } }),
          _c(
            "div",
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical-demo",
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "text-color": _vm.variables.menuText,
                    "unique-opened": true,
                    "active-text-color": _vm.variables.menuActiveText,
                    "collapse-transition": false,
                    mode: "vertical",
                    router: "",
                  },
                },
                _vm._l(_vm.permission_routers, function (route, index) {
                  return _c(
                    "el-submenu",
                    { key: index, attrs: { index: route.url + route.id } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              height: "30px",
                              width: "16px",
                              "margin-right": "6px",
                            },
                            attrs: { "icon-class": route.icon },
                          }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t(route.name)))]
                          ),
                        ],
                        1
                      ),
                      _vm._l(route.children, function (child, i) {
                        return child.url.length > 0
                          ? _c(
                              "el-menu-item",
                              { key: i, attrs: { index: child.url } },
                              [_vm._v(_vm._s(_vm.$t(child.name)))]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }