import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var roles = store.getters && store.getters.purview;
    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = roles.some(function (role) {
        return permissionRoles.includes(role);
      });
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};

// 账户管理
// 代理查询  /account/user/query
// 代理新增  /account/user/addv2
// 代理编辑    /account/user/editV2
// 代理客户解锁      /account/user/unlocked
// 代理停用 启用    /account/user/change
// 代理 起押 停押   /account/user/bet-change

// 代理 权限   /account/user/auth

// 客户新增  /account/customer/addV2
// 客户编辑 /account/customer/editV2
// 用户 额度 恢复    /account/user/recover

// 汇率查询 /report/exchangeRate/edit