var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c("div", { staticClass: "login-top" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/login/logo.png"), alt: "" },
      }),
      _c("p", [_vm._v(_vm._s(_vm.$t("代理后台")))]),
    ]),
    _c(
      "div",
      { staticClass: "login-content" },
      [
        _c("h5", { staticClass: "box-title" }, [
          _vm._v(_vm._s(_vm.$t("用户登录"))),
        ]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c(
              "el-input",
              {
                attrs: {
                  type: "text",
                  size: "medium",
                  "auto-complete": "off",
                  placeholder: _vm.$t("login.name"),
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              },
              [
                _c("template", { slot: "prepend" }, [
                  _c("i", { staticClass: "el-icon-user" }),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c(
              "el-input",
              {
                attrs: {
                  type: "password",
                  size: "medium",
                  "auto-complete": "off",
                  "show-password": true,
                  placeholder: _vm.$t("login.passWord"),
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              },
              [
                _c("template", { slot: "prepend" }, [
                  _c("i", { staticClass: "el-icon-lock" }),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c(
              "el-input",
              {
                staticStyle: { width: "63%" },
                attrs: {
                  type: "text",
                  size: "medium",
                  "auto-complete": "off",
                  placeholder: _vm.$t("login.yzm"),
                },
                model: {
                  value: _vm.loginForm.verifyCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "verifyCode", $$v)
                  },
                  expression: "loginForm.verifyCode",
                },
              },
              [
                _c(
                  "template",
                  { slot: "prepend" },
                  [
                    _c("svg-icon", {
                      staticStyle: { width: "14px", color: "#333" },
                      attrs: { "icon-class": "verify" },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("div", { staticClass: "login-code" }, [
              _c("img", {
                attrs: { src: _vm.codeUrl },
                on: { click: _vm.getCode },
              }),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "flex-lang" }, [
          _c(
            "div",
            [
              _c("svg-icon", {
                staticStyle: {
                  width: "18px",
                  color: "#333",
                  "margin-right": "8px",
                },
                attrs: { "icon-class": "global" },
              }),
              _c("p", { staticClass: "lang-title" }, [
                _vm._v(_vm._s(_vm.$t("login.yyselect"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.showLang == "zh"
                ? _c("i", { staticClass: "zh_img" })
                : _vm._e(),
              _vm.showLang == "tw"
                ? _c("i", { staticClass: "tw_img" })
                : _vm._e(),
              _vm.showLang == "en"
                ? _c("i", { staticClass: "en_img" })
                : _vm._e(),
              _vm.showLang == "th"
                ? _c("i", { staticClass: "th_img" })
                : _vm._e(),
              _vm.showLang == "vi"
                ? _c("i", { staticClass: "vi_img" })
                : _vm._e(),
              _vm.showLang == "ko"
                ? _c("i", { staticClass: "ko_img" })
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { size: "small", placeholder: _vm.$t("login.please") },
                  on: { change: _vm.langChange },
                  model: {
                    value: _vm.showLang,
                    callback: function ($$v) {
                      _vm.showLang = $$v
                    },
                    expression: "showLang",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-button",
          {
            staticClass: "login-btn",
            staticStyle: { width: "100%" },
            attrs: {
              loading: _vm.loading,
              type: "primary",
              size: "medium",
              "native-type": "submit",
            },
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleLogin($event)
              },
            },
          },
          [
            !_vm.loading
              ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("login.loginBtn")))])
              : _c("span", [_vm._v(" " + _vm._s(_vm.$t("login.loading")))]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }