/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/* 用户名校验 登录账号必须使用4-10位数字和字母组成 */
export function validateIdName(value) {
  var regex = /^(?=.*\d)(?=.*[a-zA-Z]).{4,10}$/;
  if (value == '' || value === undefined || value == null) {
    return false;
  } else {
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  }
}

/* 用户名校验 别名由1-8位数字或字母组成 */
export function validateAlias(value) {
  var regex = /^[a-zA-Z0-9]{1,8}$/;
  if (value == '' || value === undefined || value == null) {
    return false;
  } else {
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  }
}

/*  密码校验 由6-15位数字和字母组成 */
export function validatePwd(value) {
  var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/;
  if (value == '' || value === undefined || value == null) {
    return false;
  } else {
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  }
}