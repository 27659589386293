import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.regexp.replace";
import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
var publicKey = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3C+MGi/hxTdeHXsRg8kC\nbZqbRbvOPgtOl20GEyRxmxlyzCdIyfQ13eXzPWP3rBaoYNC3a6qreZp8uGX3clSE\nKnSoxXVkBMmv9I0hOyvJVsidz1F8fhj45yx+vZJGnOrEDz5568axve+QGM/FpeC9\n6oaj9fFJ1ATkh7POF6vTBMEpkTOfqqpK6o2pI/eghS4b53zsXJMY5oasIV1jAFcX\nmmRPcxhbmcyLsuSy2ccKZt7vLKP6DA2FdkjVB7eIOhF32qW2IFK7Jcm/ZRBdVz7l\n5bPnjOZoBLvetc+fd9cZ3YFOalviwHTkQ0qR0itDv8KAqvZtcT639HOTfCPBOuv0\nxwIDAQAB\n-----END PUBLIC KEY-----\n";
var privateKey = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDcL4waL+HFN14d\nexGDyQJtmptFu84+C06XbQYTJHGbGXLMJ0jJ9DXd5fM9Y/esFqhg0Ldrqqt5mny4\nZfdyVIQqdKjFdWQEya/0jSE7K8lWyJ3PUXx+GPjnLH69kkac6sQPPnnrxrG975AY\nz8Wl4L3qhqP18UnUBOSHs84Xq9MEwSmRM5+qqkrqjakj96CFLhvnfOxckxjmhqwh\nXWMAVxeaZE9zGFuZzIuy5LLZxwpm3u8so/oMDYV2SNUHt4g6EXfapbYgUrslyb9l\nEF1XPuXls+eM5mgEu961z5931xndgU5qW+LAdORDSpHSK0O/woCq9m1xPrf0c5N8\nI8E66/THAgMBAAECggEACGOjYegLIRl/832Z6DyDZCus2NZUAFcr8mcMATS1Q2SP\n1Cl3A4LTfpXhU6h4ak0eszmgk3CYQfTZ5ai06d1Af+GcAJH9cpVAp/+skXWUoULZ\niGmhZaiL6zrtxUlKeXYUbEREHvCeGw+CnP9ll3Fdqw7gNmGwH2zkTCtUoeBBFQA4\n/Ew4s6x9zg/ahIc/RzvFiw28AdsqYk08F8Z+Q48aAu51oMgcsTR06CDvr16dYx2j\n/Kcnx6cYepDE79F1j9jPyejh4tQPCXVSflFMXnXedpeAMVETtDltO296L1g92JtF\nCrXmc6jnxL8R6+oJKoGET0g1b7mKbOxXAgYOrf9buQKBgQD1MkGrZ56ALCeFU1GD\nXoGhLbOjhqsAeczFjbVfWtloxqTlbpvN3hE9VjiyZS2hZgoRVOy7H7Avmerj48e8\nxHHq098GDhR95ILv5PNJCX29ie+O7dA3zC8SSO66wC55NB5PXyDIgJdPu24lOQfE\n9rVl8IjymQTb2i5sNFcchtJo/QKBgQDl4y25oSRzcJ532EJ7N8o75aM+ApbFIMuz\nAbS5G1refzhvu+1Hx7YrIveu0NbYra63rIn9H4lN5hTKok6W77CbaIOoneKY9chn\nlDq9mA8vfOK3EFjTglpptbMaw+rwv2rS9gO5NI0ogdQnZZLt96EQZN7L8nlonTdR\n5IznivvyEwKBgFg0tBsNM5+4Qp9b0b8IiGmLH0x8bZfGOTv4Nn8PF5bxnvBKUhJF\nCL6f/dVvQHMHWifR/TdzttXo8O76SihGIxTvExQCjKydP90856gmme1NabhpJLZt\n5aEWS4szB4TikuTKu+hlGgm8y2rokTrx5V++oAVltrHAU2LpkUzZ02ehAoGBAKL3\ncIPGm2uGq6Az27Uz8JaTjSy5cx5dyGmmzPHoVXujXmO/Kk+XYSmlSUZRX+0M+u3U\nT6H0SEbD03tuoh3X3y/cYHEZM/jRS/c5moarFCDK/IFgAkg2gVes6kIQHy351IWm\nxy709RiBKN+g3w75W+GeUF7uDmk+Amh4h/bgjQinAoGATbRAzSx1KMGOTHwZh4S7\nedES/ZcEHihjNlNgy+AYj/jo0K3MDR6HPSstSCBPtZQZNrzVAVZ88HCd904t+rdm\nHV/28Ai8VcHj78byDw3NS/MraUNbedktWKPMdRyKlVFSGP9JmXnrCmZnZbyxDkoL\nXXQJN/He3tuk7BfEXpEkvrI=\n-----END PRIVATE KEY-----\n";
var publicKeyStr = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeFr3sIZlXSXn8g+tG8pSUnPfX3kewpos9iDn26hhzOUxSxZ96yr6Op/p9DeBDvfs1MRWIkYjw5smYCjXxOvdTy3d445sMXR9WT9PGXexQOnnDTajqHj1O08QgvDlpnJv8BsUKgEwps9XgnNpAx0QZXh8nsJg5w9CH1mKIvNc6ewIDAQAB";

// 加密
export function encrypt(txt) {
  var encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

// 解密
export function getcrypt(txt) {
  var decrypt = new JSEncrypt(txt);
  decrypt.setPrivateKey(privateKey);
  return decrypt.decrypt(txt);
}

// 生成UUID
export function getUUID() {
  return uuidv4().replace(/-/g, '').substring(0, 20);
}

// 生成UUID
export function uniqueID() {
  return uuidv4();
}
export function encodeByMD5(originstr) {
  if (originstr != null) {
    var md5Hash = CryptoJS.MD5(originstr);
    var resultArray = new Uint8Array(md5Hash.words.length * 4);
    var offset = 0;
    for (var i = 0; i < md5Hash.words.length; i++) {
      var word = md5Hash.words[i];
      resultArray[offset++] = word >> 24;
      resultArray[offset++] = word >> 16 & 0xff;
      resultArray[offset++] = word >> 8 & 0xff;
      resultArray[offset++] = word & 0xff;
    }
    return bytesToHexString(resultArray);
  }
  return null;
}
function bytesToHexString(array) {
  var buf = '';
  for (var i = 0; i < array.length; i++) {
    var hex = (array[i] & 0xff).toString(16);
    hex = hex.length === 1 ? '0' + hex : hex;
    buf += hex;
  }
  return buf;
}

//公钥加密
export function encryptWithRsa(plaintext) {
  var encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKeyStr); // 设置公钥
  return encryptor.encrypt(plaintext); // 对需要加密的数据进行加密
}