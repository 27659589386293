import router from "./routers";
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie

NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = '代理管理网';
  }
  NProgress.start();
  if (getToken()) {
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      next();
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
      NProgress.done();
    }
  }
});
router.afterEach(function () {
  NProgress.done(); // finish progress bar
});