import request from '@/utils/request';

// 角色列表
export function roleQuery(name) {
  return request({
    url: "/as/system/role/query?name=".concat(name),
    method: 'GET'
  });
}

// 角色详情
export function roleDesc(id) {
  return request({
    url: "/as/system/role/query-desc?roleId=".concat(id),
    method: 'GET'
  });
}