var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        [
          _c("i", {
            staticClass: "el-icon-d-arrow-left tags-view-item",
            on: { click: _vm.onPre },
          }),
          _vm._l(this.$store.state.tabList.list, function (tag, index) {
            return _c(
              "router-link",
              {
                key: tag.path,
                ref: "tag",
                refInFor: true,
                staticClass: "tags-view-item",
                class: _vm.isActive(tag) ? "active" : "",
                attrs: {
                  to: {
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath,
                  },
                  tag: "span",
                },
                nativeOn: {
                  contextmenu: function ($event) {
                    $event.preventDefault()
                    return _vm.openMenu(tag, $event)
                  },
                },
              },
              [
                tag.path == "/dashboard"
                  ? [
                      _c("i", {
                        staticClass: "el-icon-house home-style",
                        attrs: { title: _vm.$t("home.homePage") },
                      }),
                    ]
                  : [_vm._v(" " + _vm._s(_vm.$t(tag.name)) + " ")],
                tag.path != "/dashboard"
                  ? _c("span", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.closeSelectedTag(tag, index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            )
          }),
          _c(
            "div",
            { staticClass: "right-icon" },
            [
              _c("i", {
                staticClass: "el-icon-d-arrow-right tags-view-item",
                on: { click: _vm.onNext },
              }),
              _c(
                "el-dropdown",
                { on: { command: _vm.closeCTag } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("i", {
                      staticClass:
                        "el-icon-arrow-down el-icon--right tags-view-item",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "cur" } }, [
                        _vm._v(_vm._s(_vm.$t("关闭当前标签页"))),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "other" } }, [
                        _vm._v(_vm._s(_vm.$t("关闭其他标签页"))),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "all" } }, [
                        _vm._v(_vm._s(_vm.$t("关闭全部标签页"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }