var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "menuId",
                border: "",
                "cell-style": { borderColor: "#e6e6e6" },
                "header-cell-style": {
                  background: "#f97802",
                  height: "50px",
                  color: "rgba(255, 255, 255, 1)",
                  borderColor: "#e6e6e6",
                },
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "menuName",
                  label: _vm.$t("systemMenu.menu_name"),
                  width: "280",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "icon", label: _vm.$t("systemMenu.menu_icon") },
              }),
              _c("el-table-column", {
                attrs: { prop: "url", label: _vm.$t("systemMenu.caidanURl") },
              }),
              _c("el-table-column", {
                attrs: { prop: "perms", label: _vm.$t("systemMenu.perms") },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: _vm.$t("systemMenu.menu_type"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 0
                          ? _c(
                              "p",
                              {
                                attrs: {
                                  icon: "el-icon-edit",
                                  type: "primary",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("systemMenu.form_menu")) + " "
                                ),
                              ]
                            )
                          : _c(
                              "p",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  icon: "el-icon-delete",
                                  type: "danger",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("systemMenu.form_btn")))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            title:
              _vm.editType == 0
                ? _vm.$t("systemMenu.add")
                : _vm.$t("systemMenu.edit"),
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemMenu.menu_type"),
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { width: "178px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v(_vm._s(_vm.$t("systemMenu.form_menu"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v(_vm._s(_vm.$t("systemMenu.form_btn"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.type.toString() !== "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("systemMenu.form_title"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        style:
                          _vm.form.type.toString() === "0"
                            ? "width: 450px"
                            : "width: 178px",
                        attrs: { placeholder: _vm.$t("systemMenu.form_title") },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "1",
                      expression: "form.type.toString() !== '1'",
                    },
                  ],
                  attrs: {
                    label: _vm.$t("systemMenu.form_icon"),
                    prop: "icon",
                  },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        width: "450",
                        trigger: "click",
                      },
                      on: {
                        show: function ($event) {
                          return _vm.$refs["iconSelect"].reset()
                        },
                      },
                    },
                    [
                      _c("IconSelect", {
                        ref: "iconSelect",
                        on: { selected: _vm.selected },
                      }),
                      _c(
                        "el-input",
                        {
                          attrs: {
                            slot: "reference",
                            placeholder: _vm.$t("systemMenu.form_iconTips"),
                            readonly: "",
                          },
                          slot: "reference",
                          model: {
                            value: _vm.form.icon,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icon", $$v)
                            },
                            expression: "form.icon",
                          },
                        },
                        [
                          _vm.form.icon
                            ? _c("svg-icon", {
                                staticClass: "el-input__icon",
                                staticStyle: { height: "32px", width: "16px" },
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": _vm.form.icon,
                                },
                                slot: "prefix",
                              })
                            : _c("i", {
                                staticClass: "el-icon-search el-input__icon",
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.type.toString() === "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("systemMenu.form_title_menu"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("systemMenu.form_title_menu"),
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemMenu.form_address"),
                    prop: "url",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("systemMenu.form_address") },
                    model: {
                      value: _vm.form.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("systemMenu.form_parent"),
                    prop: "parentId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.options,
                      placeholder: _vm.$t("systemMenu.form_parent"),
                    },
                    model: {
                      value: _vm.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentId", $$v)
                      },
                      expression: "form.parentId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("systemMenu.form_cancel")))]
              ),
              _vm.editType == 0
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v(_vm._s(_vm.$t("systemMenu.form_sure")))]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.edit } },
                    [_vm._v(_vm._s(_vm.$t("systemMenu.form_sure")))]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }