import i18n from "../lang";
var serverMsgList = [{
  "key": "200",
  "tips": i18n.t('serverMsg.200'),
  "default": "操作成功"
}, {
  "key": "400",
  "tips": i18n.t('serverMsg.400'),
  "default": "参数错误"
}, {
  "key": "401",
  "tips": i18n.t('serverMsg.401'),
  "default": "未登录或 TOKEN 已经过期"
}, {
  "key": "402",
  "tips": i18n.t('serverMsg.402'),
  "default": "角色错误"
}, {
  "key": "403",
  "tips": i18n.t('serverMsg.403'),
  "default": "没有操作权限"
}, {
  "key": "404",
  "tips": i18n.t('serverMsg.404'),
  "default": "请求地址错误"
}, {
  "key": "405",
  "tips": i18n.t('serverMsg.405'),
  "default": "请求方法不支持"
}, {
  "key": "406",
  "tips": i18n.t('serverMsg.406'),
  "default": "子账号没有查询本级操作权限"
}, {
  "key": "500",
  "tips": i18n.t('serverMsg.500'),
  "default": "操作失败，未知错误"
}, {
  "key": "2001",
  "tips": i18n.t('serverMsg.2001'),
  "default": "数据未找到"
}, {
  "key": "4001",
  "tips": i18n.t('serverMsg.4001'),
  "default": "参数校验错误"
}, {
  "key": "4002",
  "tips": i18n.t('serverMsg.4002'),
  "default": "校验码错误"
}, {
  "key": "4003",
  "tips": i18n.t('serverMsg.4003'),
  "default": "参数错误:上级ID与用户类型不匹配"
}, {
  "key": "4011",
  "tips": i18n.t('serverMsg.4011'),
  "default": "用户已停用"
}, {
  "key": "4012",
  "tips": i18n.t('serverMsg.4012'),
  "default": "用户不存在"
}, {
  "key": "4013",
  "tips": i18n.t('serverMsg.4013'),
  "default": "账号或密码不正确"
}, {
  "key": "4014",
  "tips": i18n.t('serverMsg.4014'),
  "default": "旧密码错误"
}, {
  "key": "4015",
  "tips": i18n.t('serverMsg.4015'),
  "default": "名称已存在"
}, {
  "key": "4016",
  "tips": i18n.t('serverMsg.4016'),
  "default": "导出受限，请缩小查询范围"
}, {
  "key": "4017",
  "tips": i18n.t('serverMsg.4017'),
  "default": "账号被锁定"
}, {
  "key": "4018",
  "tips": i18n.t('serverMsg.4018'),
  "default": "修改金额不能大于上级并且不能小于下级"
}, {
  "key": "4019",
  "tips": i18n.t('serverMsg.4019'),
  "default": "旧密码错误"
}, {
  "key": "4031",
  "tips": i18n.t('serverMsg.4031'),
  "default": "占成/佣金只能在游戏维护期间方可修改"
}, {
  "key": "5001",
  "tips": i18n.t('serverMsg.5001'),
  "default": "网络异常"
}, {
  "key": "5002",
  "tips": i18n.t('serverMsg.5002'),
  "default": "操作失败，业务错误"
}, {
  "key": "5003",
  "tips": i18n.t('serverMsg.5003'),
  "default": "非法操作"
}, {
  "key": "5004",
  "tips": i18n.t('serverMsg.5004'),
  "default": "上游响应异常"
}, {
  "key": "5005",
  "tips": i18n.t('serverMsg.5005'),
  "default": "上游返回为空"
}, {
  "key": "6001",
  "tips": i18n.t('serverMsg.6001'),
  "default": "上级不存在"
}, {
  "key": "6002",
  "tips": i18n.t('serverMsg.6002'),
  "default": "上级不存在或类型错误"
}, {
  "key": "7000",
  "tips": i18n.t('serverMsg.7000'),
  "default": "余额查询错误"
}, {
  "key": "7001",
  "tips": i18n.t('serverMsg.7001'),
  "default": "会员未开通此游戏"
}, {
  "key": "7002",
  "tips": i18n.t('serverMsg.7002'),
  "default": "存取点数不能为0"
}, {
  "key": "7003",
  "tips": i18n.t('serverMsg.7003'),
  "default": "上级余额不足"
}, {
  "key": "7004",
  "tips": i18n.t('serverMsg.7004'),
  "default": "当前余额不足"
}, {
  "key": "7005",
  "tips": i18n.t('serverMsg.7005'),
  "default": "当前代理余额不足"
}, {
  "key": "7006",
  "tips": i18n.t('serverMsg.7006'),
  "default": "该笔交易已失效:请重新发起下笔交易"
}, {
  "key": "7007",
  "tips": i18n.t('serverMsg.7007'),
  "default": "无法给自己存款:请联系上级"
}, {
  "key": "7008",
  "tips": i18n.t('serverMsg.7008'),
  "default": "仅支持代理存取款"
}, {
  "key": "7009",
  "tips": i18n.t('serverMsg.7009'),
  "default": "存取款游戏类型错误"
}, {
  "key": "7010",
  "tips": i18n.t('serverMsg.7010'),
  "default": "存取款操作频繁:稍后再试"
}, {
  "key": "7011",
  "tips": i18n.t('serverMsg.7011'),
  "default": "无法对子账号进行操作:请联系上级"
}, {
  "key": "7012",
  "tips": i18n.t('serverMsg.7012'),
  "default": "仅支持会员存取款"
}, {
  "key": "7014",
  "tips": i18n.t('serverMsg.7014'),
  "default": "暂无会员数据"
}, {
  "key": "7015",
  "tips": i18n.t('serverMsg.7015'),
  "default": "超过存取款金额限制"
}, {
  "key": "7019",
  "tips": i18n.t('serverMsg.7019'),
  "default": "余额不足:会员存取款失败"
}, {
  "key": "7020",
  "tips": i18n.t('serverMsg.7020'),
  "default": "存取款失败:业务错误"
}, {
  "key": "7021",
  "tips": i18n.t('serverMsg.7021'),
  "default": "会员普通限额不能小于一个:且不能大于三个:请重新勾选"
}, {
  "key": "7025",
  "tips": i18n.t('serverMsg.7025'),
  "default": "上级已经更改信息,请重新设置"
}, {
  "key": "7022",
  "tips": i18n.t('serverMsg.7022'),
  "default": "真人游戏点数为空"
}, {
  "key": "7023",
  "tips": i18n.t('serverMsg.7023'),
  "default": "总代理以上或是子账号无法设置上线提醒"
}, {
  "key": "7024",
  "tips": i18n.t('serverMsg.7024'),
  "default": "账户名4-10位字母或数字组合"
}, {
  "key": "8000",
  "tips": i18n.t('serverMsg.8000'),
  "default": "账单记录失败"
}, {
  "key": "9000",
  "tips": i18n.t('serverMsg.9000'),
  "default": "仅支持查询代理"
}, {
  "key": "10000",
  "tips": i18n.t('serverMsg.10000'),
  "default": "游戏维护中"
}, {
  "key": "4022",
  "tips": i18n.t('serverMsg.4022'),
  "default": "会员禁止登录管理系统"
}];

/**
 * @param {string} [code]  错误码
 * @param {msg} [msg] 错误描述
 */
export var serverMsg = function serverMsg(code) {
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '上游响应异常';
  if (code == '5004') {
    return msg;
  } else {
    var msgTarget = serverMsgList.filter(function (item) {
      return item.key == code;
    });
    return msgTarget.length > 0 ? msgTarget[0].tips : "ERRCODE--".concat(code);
  }
};