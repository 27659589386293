import request from '@/utils/request';

// 获取通告列表
export function getNoticeList(data) {
  return request({
    url: '/setting/notice/getList',
    method: 'post',
    data: data
  });
}
export function deleteNotice(data) {
  return request({
    url: '/setting/notice/delete',
    method: 'post',
    data: data
  });
}

// 新增通告
export function saveNotice(data) {
  return request({
    url: '/setting/notice/save',
    method: 'post',
    data: data
  });
}

// 发布通告
export function publishNotice(data) {
  return request({
    url: '/setting/notice/publish',
    method: 'post',
    data: data
  });
}

// 更新通告
export function updateNotice(data) {
  return request({
    url: '/setting/notice/update',
    method: 'post',
    data: data
  });
}

// 停用通告
export function disabledNotice(data) {
  return request({
    url: '/setting/notice/disabled',
    method: 'post',
    data: data
  });
}

// 删除通告

// 获取通告-用户端
export function getNotice(data) {
  return request({
    url: '/setting/notice/get',
    method: 'post',
    data: data
  });
}

// 获取通告-用户端
export function homeNotice(data) {
  return request({
    url: '/home/getHomePageList',
    method: 'post',
    data: data
  });
}