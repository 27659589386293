module.exports = {
  /**
   * @description
   */
  title: '代理管理网',
  /**
   * @description
   */
  tagsView: true,
  /**
   * @description
   */
  fixedHeader: true,
  /**
   * @description 
   */
  tokenCookieExpires: 1,
  /**
   * @description 
   */
  passCookieExpires: 1,
  /**
   * @description 
   */
  es: ['0', '0', '0', '0', '0', '0', '4', '8', '3', '8', '2', '7', '1'],
  /**
   * @description 
   */
  uniqueOpened: true,
  /**
   * @description 
   */
  TokenKey: 'agent',
  /**
   * @description 
   */
  timeout: 1200000,
  /**
   * @description 
   */
  sidebarLogo: true,
  /**
   * 
   */
  showFooter: true,
  /**
   * 
   */
  footerTxt: '© 2023 代理后台',
  // footerTxt: '© 2023 agent. LTD. <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  /**
   * 
   */
  caseNumber: ''
};