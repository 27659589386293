import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import _toConsumableArray from "/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { serverMsg } from '@/utils/serverMsg';
import { roleQuery, roleDesc } from '@/api/system/role';
export default {
  name: 'Role',
  data: function data() {
    return {
      roleForm: {
        name: '',
        code: '',
        remark: '',
        menuIds: []
      },
      activeMenuIds: [],
      dialog: false,
      loading: false,
      defaultProps: {
        children: 'children',
        label: 'menuName',
        isLeaf: 'leaf'
      },
      tableData: [],
      queryForm: {
        "name": "" // 角色名称
      },
      options: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 获取角色列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      roleQuery(this.queryForm.name).then(function (res) {
        if (res.code == 200) {
          _this.loading = false;
          _this.tableData = res.data;
          _this.tableData.sort(function (a, b) {
            return a.roleId - b.roleId;
          });
        } else {
          _this.$alert(serverMsg(res.code, res.message), {
            title: _this.$i18n.t('systemRole.wxts'),
            type: 'warning'
          });
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    editSubmit: function editSubmit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var allKeys = [].concat(_toConsumableArray(_this2.$refs.menuListTree.getCheckedKeys()), _toConsumableArray(_this2.$refs.menuListTree.getHalfCheckedKeys()));
          var reqData = {
            menuIds: allKeys,
            id: _this2.roleForm.id,
            name: _this2.roleForm.roleName,
            remark: _this2.roleForm.remark
          };
          crudRoles.edit(reqData).then(function (res) {
            if (res.code == 200) {
              _this2.dialog = false;
              _this2.getList(1);
            } else {
              _this2.$alert(serverMsg(res.code, res.message), {
                title: _this2.$i18n.t('systemRole.wxts'),
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 时间过滤
    timeFormatter: function timeFormatter(time) {
      var date = time.substr(0, 10); // 年月日
      var hours = time.substring(11, 13);
      var minutes = time.substring(14, 16);
      var seconds = time.substring(17, 19);
      var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
      timeFlag = timeFlag.replace(/-/g, "/");
      timeFlag = new Date(timeFlag);
      timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
      timeFlag = timeFlag.getFullYear() + '-' + (timeFlag.getMonth() + 1 < 10 ? "0" + (timeFlag.getMonth() + 1) : timeFlag.getMonth() + 1) + '-' + (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' + timeFlag.getHours() + ':' + timeFlag.getMinutes() + ':' + (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
      return timeFlag;
    },
    // 编辑角色
    editRole: function editRole(item) {
      var _this3 = this;
      roleDesc(item.roleId).then(function (res) {
        if (res.code == 200) {
          var treeList = res.data;
          var disableList = _this3.disabledTree(treeList);
          _this3.options = disableList;
          var menuIds = _this3.deepTree(treeList, []);
          _this3.$nextTick(function () {
            var arr = [];
            menuIds.forEach(function (item) {
              if (!_this3.$refs.menuListTree.getNode(item).childNodes || !_this3.$refs.menuListTree.getNode(item).childNodes.length) {
                arr.push(item);
              }
            });
            _this3.$refs.menuListTree.setCheckedKeys(arr);
          });
          _this3.editType = 1;
          _this3.dialog = true;
          _this3.roleForm = {
            id: item.roleId,
            name: item.roleName,
            code: item.roleTag,
            remark: item.remark,
            menuIds: menuIds
          };
        }
      });
    },
    // 编辑角色提交
    // 深层遍历
    deepTree: function deepTree(data) {
      var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (item.checked) {
          arr.push(item.menuId);
        }
        if (item.children && item.children.length) {
          this.deepTree(item.children, arr);
        }
      }
      return arr;
    },
    // 深层遍历
    deepTreeAll: function deepTreeAll(data) {
      var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      for (var i = 0; i < data.length; i++) {
        var item = data[i];
        arr.push(item.menuId);
        if (item.children && item.children.length) {
          this.deepTreeAll(item.children, arr);
        }
      }
      return arr;
    },
    disabledTree: function disabledTree(list) {
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = true;
        if (list[i].children && list[i].children.length) {
          this.disabledTree(list[i].children);
        }
      }
      return list;
    },
    disabledDefault: function disabledDefault(list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].url.includes('/system') || list[i].url.includes('/setting')) {
          list[i].disabled = true;
          if (list[i].children && list[i].children.length) {
            this.disabledDefault(list[i].children);
          }
        }
      }
      return list;
    }
  }
};