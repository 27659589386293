var langType = {
  state: {
    lang: 'zh'
  },
  mutations: {
    SET_LANG: function SET_LANG(state, type) {
      state.lang = type;
    }
  },
  actions: {
    setLang: function setLang(_ref, type) {
      var commit = _ref.commit;
      commit('SET_LANG', type);
    }
  }
};
export default langType;