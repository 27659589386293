import { Loading } from 'element-ui';
import i18n from "../lang";
import Vue from 'vue';
var needLoadingRequestCount = 0;
var loading;
var startLoading = function startLoading() {
  loading = Loading.service({
    lock: true,
    text: i18n.tc('拼命加载中') + '...',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, .6)'
  });
};
function endLoading() {
  Vue.nextTick(function () {
    loading.close();
  });
}
export var showLoading = function showLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
};
export var hideLoading = function hideLoading() {
  setTimeout(tryCloseLoading, 300);
};
function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}